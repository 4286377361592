<template>
  <div>
    <EngineerReports />
  </div>
</template>

<script>
import EngineerReports from "./EngineerReports";
export default {
  name: "MainReportPage",
  components: { EngineerReports },
};
</script>

<style scoped></style>
