<template>
  <div class="p-grid">
    <div class="p-col-12">
      <DataTable
        :value="engineers"
        ref="reportEngineerTable"
        :paginator="true"
        dataKey="id"
        class="p-datatable-customers p-datatable-striped"
        :rows="10"
        :rowHover="true"
        :autoLayout="true"
        :loading="isLoading"
        @row-click="goToCurrentEngineerReport"
        selectionMode="single"
        state-storage="session"
        state-key="engineers-report"
      >
        <template #header>
          <div class="p-d-flex p-jc-between p-flex-column">
            <div class="table-header p-col-12 p-mb-4">
              {{ tableTitle }}
            </div>

            <div class="p-d-flex p-flex-row p-jc-between">
              <div class="filters p-d-flex p-flex-row">
                <MultiSelect
                  id="engineer"
                  v-model="selectedEngineers"
                  :options="allEngineers"
                  placeholder="Сотрудники"
                  optionLabel="nameText"
                  optionValue="id"
                  emptyFilterMessage="Совпадений не найдено"
                  class="multiselect p-mr-4"
                  :filter="true"
                />

                <MultiSelect
                  id="workTypes"
                  v-model="selectedWorkTypes"
                  :options="workTypes"
                  placeholder="Типы работ"
                  optionLabel="name"
                  optionValue="value"
                  emptyFilterMessage="Совпадений не найдено"
                  class="multiselect p-mr-4"
                  :filter="true"
                />

                <RangeDatePicker v-model:range="filterDate" />
              </div>

              <div class="buttons">
                <Button
                  @click="loadFilteredReport"
                  class="p-button-success p-mr-2"
                >
                  Сформировать отчет
                </Button>
                <Button
                  @click="downloadReport"
                  class="p-button-success p-mr-2"
                  :disabled="!urlWithFilters"
                >
                  Скачать отчет
                </Button>
                <Button class="p-button-danger" @click="clearFilters">
                  Очистить фильтры
                </Button>
              </div>
            </div>
          </div>
        </template>
        <template #empty>Заявки не найдены</template>
        <template #loading>Загружаем заявки...</template>

        <Column
          field="name"
          header="Инженер"
          :sortable="true"
          :showFilterMenu="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.name }}
          </template>

          <template #filter="{}">
            <InputText />
          </template>
        </Column>

        <Column
          field="formattedTime"
          header="Отработанное время"
          :sortable="true"
          :showFilterMenu="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.formattedTime }}
          </template>
        </Column>

        <Column
          field="distance"
          header="Пройденное расстояние"
          :sortable="true"
          :showFilterMenu="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.distance }} км.
          </template>
        </Column>
        <Column
          field="sumWorkTime"
          header="Суммарное время работы (c взятия в работу до закрытия инженером)"
          :sortable="true"
          :show-filter-menu="false"
        />
        <Column
          field="avgTakeToWorkTime"
          header="Среднее время взятия в работу (с назначения до взятия в работу)"
          :sortable="true"
          :show-filter-menu="false"
        />
      </DataTable>
    </div>
  </div>
</template>

<script>
import { environment } from "@/config";
import { Calendar, DatePicker } from "v-calendar";
import RangeDatePicker from "../../operator/components/RangeDatePicker";
import { mapGetters } from "vuex";

export default {
  components: {
    RangeDatePicker,
    Calendar,
    DatePicker,
  },
  name: "EngineerReports",
  data() {
    return {
      urlWithFilters: null,
      selectedWorkTypes: [],
      selectedEngineers: [],
      filterDate: {
        start: new Date(),
        end: new Date(),
      },
      engineers: [],
      isLoading: false,
      tableTitle: "Отчет по инженерам",
    };
  },
  computed: {
    ...mapGetters({
      allEngineers: "myprofiles/engineers",
      workTypes: "data/workTypes",
    }),
  },
  watch: {
    workTypes(newValue) {
      if (!this.selectedWorkTypes.length)
        this.selectedWorkTypes = newValue.map((workType) => workType.value);
    },
  },
  async mounted() {
    let savedFilterData = JSON.parse(
      window.sessionStorage.getItem("reportFilters")
    );

    if (savedFilterData) {
      this.selectedEngineers = savedFilterData.engineersId;
      this.selectedWorkTypes = savedFilterData.workTypes;
      this.filterDate = {
        start: new Date(savedFilterData.dateStart),
        end: new Date(savedFilterData.dateEnd),
      };

      await this.loadFilteredReport();
      return;
    }

    this.selectedWorkTypes = this.workTypes.map((type) => type.value);
  },
  methods: {
    goToCurrentEngineerReport(data) {
      data = data.data;
      this.$router.push(
        `/engineerReport?engineerId=${
          data.id
        }&dateStart=${this.filterDate.start.toISOString()}&dateEnd=${this.filterDate.end.toISOString()}&workTypes=${
          this.selectedWorkTypes
        }`
      );
    },
    async downloadReport() {
      this.isLoading = true;

      try {
        const response = await fetch(
          `${environment.apiUrl}/reports/engineers/excel${this.urlWithFilters}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        );
        const url = window.URL.createObjectURL(await response.blob());
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Отчет.xls`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
    },
    clearFilters() {
      this.selectedWorkTypes = [];
      this.selectedEngineers = [];
      window.sessionStorage.removeItem("reportFilters");
      this.filterDate = {
        start: new Date(),
        end: new Date(),
      };
    },
    createTableDataModel(data) {
      return data.map((e) => {
        return {
          ...e,
          name: `${e.lastname} ${e.firstname}`,
          formattedTime: this.getHoursFromMinutes(e.time),
          sumWorkTime: this.getHoursFromSeconds(e.sumWorkTime),
          avgTakeToWorkTime: this.getHoursFromSeconds(e.avgTakeToWorkTime),
        };
      });
    },
    getHoursFromSeconds(time) {
      if (time !== 0) {
        time = Math.floor(time / 60);
        let hours = Math.floor(time / 60);
        let minutes = time % 60;
        return `${hours} ч. ${minutes} мин.`;
      } else {
        return `0 ч. 0 мин.`;
      }
    },
    getHoursFromMinutes(time) {
      if (time !== 0) {
        let hours = Math.floor(time / 60);
        let minutes = time % 60;
        return `${hours} ч. ${minutes} мин.`;
      } else {
        return `0 ч. 0 мин.`;
      }
    },
    async loadFilteredReport() {
      this.isLoading = true;
      this.engineers = [];

      try {
        let selectedEngineersId = "";
        for (const id of this.selectedEngineers) {
          selectedEngineersId += `&engineerIds=${id}`;
        }

        let selectedWorkTypes = "";
        for (const type of this.selectedWorkTypes) {
          selectedWorkTypes += `&workTypes=${type}`;
        }

        this.filterDate.start.setHours(0, 0, 0, 0);
        this.filterDate.end.setHours(23, 59, 59, 0);

        let reportFilters = {
          engineersId: this.selectedEngineers,
          dateStart: this.filterDate.start.toISOString(),
          dateEnd: this.filterDate.end.toISOString(),
          workTypes: this.selectedWorkTypes,
        };

        window.sessionStorage.setItem(
          "reportFilters",
          JSON.stringify(reportFilters)
        );

        this.urlWithFilters =
          `?${selectedEngineersId}&dateStart=${this.filterDate.start.toISOString()}` +
          `&dateEnd=${this.filterDate.end.toISOString()}${selectedWorkTypes}`;

        const response = await fetch(
          `${environment.apiUrl}/reports/engineers${this.urlWithFilters}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        this.engineers = await response.json();
        this.engineers = this.createTableDataModel(this.engineers);

        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Отчет сформирован`,
          life: 6000,
        });
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.multiselect {
  width: 190px;
  color: black;
}
</style>
